.center {
    display: flex;
    justify-content: center;

    ion-card {
        width: 90%;
        max-width: 500px;
    }

    ion-card-subtitle {
        display: flex;
        justify-content: center;
        padding: 10px;
        margin-right: 10px;
        color: black;
        font-weight: bold;
    }

    h2 {
        color: black;
        font-size: 12px;
    }

    ion-col {
        padding-left: 20px;
    }
}

.customCenter {
    display: flex;
    justify-content: center;
}