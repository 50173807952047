.detailProducts {
    
    fieldset {
        border: 1px solid rgb(180, 23, 73);
        border-radius: 5px;
    }

    fieldset legend {
        color: white;
        padding: 5px 10px;
        border-radius: 5px;
        box-shadow: 0 0 0 1px rgba(180, 23, 73, 1);
        background: rgb(180, 23, 73);
    }
}
.detailItems {
    border-bottom: 1px solid rgb(180, 23, 73);
}
.scrollContainer {
    max-height: 300px;
    overflow-y: auto; 
}

.information{
    color: black;
}