.field{
    background: rgba(180, 23, 73, 1);
    color: white;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    margin-top: 5px;
    margin-bottom: 15px;
    height: 36px;
}

.item{
    opacity: 80%;
    border-radius: 4px;
    height: 25px;
    min-height: 25px;
    --min-height: 25px;
}

.paginator{
    height: 25px;
    margin: 0px;
}