
.Row {
    overflow: auto;
    height: 75vh;
    color: black;

    ion-card {
        box-shadow: 1px 1px 0px 2px rgb(180, 23, 73);
        color: black;
        height: 95px;

        ion-card-header{
            justify-content: center;
            display: flex;
            padding: 2px;
        }

        ion-card-content{
            text-align: center;
            font-size: 11px;
        }

        ion-icon{
            font-size: 35px;
        }
    }

    ion-item {
        --border-color: rgb(180, 23, 73);
    }
}