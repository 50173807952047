.content {
    height: 84vh;

    fieldset {
        border: 1px solid rgb(180, 23, 73);
        border-radius: 5px;
    }

    fieldset legend {
        color: white;
        padding: 5px 10px;
        border-radius: 5px;
        box-shadow: 0 0 0 1px rgba(180, 23, 73, 1);
        background: rgb(180, 23, 73);
    }
}

.header {
    background: rgba(180, 23, 73, 1);
    color: white;
}

.Row {
    overflow: auto;
    height: 45vh;

    ion-card {
        box-shadow: 1px 1px 0px 2px rgb(180, 23, 73);
        color: black;
        height: 95px;

        ion-card-header{
            justify-content: center;
            display: flex;
            padding: 2px;
        }

        ion-card-content{
            text-align: center;
            font-size: 11px;
        }

        ion-icon{
            font-size: 35px;
        }
    }

    ion-row {
        border-bottom: 1px solid rgb(180, 23, 73);
    }
}